<template v-cloak>
  <!-- 付款码 -->
  <div class="person-view">
    <div class="nav">
      <div class="nav_title">个人信息</div>
      <div class="nav_left_btn" @click="toBack">
        <img :src="cancel" alt width="15" height="25" />
      </div>
    </div>

    <form class="person-view-main">
      <div class="person-view-info">
        <div v-cloak class="person-view-info-left">
          <img
            :src="
              infoData.photo ? `${apiUrl}/image?name=${infoData.photo}` : none
            "
            class="info-logo"
          />
          <!-- <img :src="none" class="info-logo"> -->
          <!-- <img v-if="infoData.photo" :src="`${this.apiUrl}/image?name=${this.picUrl}`" class="info-logo">
          <img v-else :src="require('@/assets/images/'+ this.picUrl +'photo_none.png')" class="info-logo">-->
          <div class="cover">
            <!-- 根据isReview的值来判断审核状态，-1：不用审核；0：审核中；1：通过；2：拒绝 -->
            <div v-show="infoData.isReview===0" class="info-cover">
              <div class="cover-text">
                <div>审核中</div>
              </div>
              <img
                :src="
                  infoData.photo ? `${apiUrl}/image?name=${infoData.photo}` : none
                "
                class="info-logo"
              />
            </div>
            <!-- <div v-show="infoData.isReview===1" class="info-cover">
              <div class="cover-text"><div>审核通过</div></div>
              <img
                :src="
                  infoData.photo ? `${apiUrl}/image?name=${infoData.photo}` : none
                "
                class="info-logo"
              >
            </div>-->
            <div v-show="infoData.isReview===2" class="info-cover">
              <div class="cover-text">
                <div>审核拒绝</div>
              </div>
              <img
                :src="
                  infoData.photo ? `${apiUrl}/image?name=${infoData.photo}` : none
                "
                class="info-logo"
              />
            </div>
            <div v-show="infoData.isReview===3" class="info-cover">
              <div class="cover-text">
                <div>下发中</div>
              </div>
              <img
                :src="
                  infoData.photo ? `${apiUrl}/image?name=${infoData.photo}` : none
                "
                class="info-logo"
              />
            </div>
            <!-- <div v-show="infoData.isReview===4" class="info-cover">
              <div class="cover-text"><div>下发成功</div></div>
              <img
                :src="
                  infoData.photo ? `${apiUrl}/image?name=${infoData.photo}` : none
                "
                class="info-logo"
              >
            </div>-->
            <div v-show="infoData.isReview===5" class="info-cover">
              <div class="cover-text">
                <div>下发失败</div>
              </div>
              <img
                :src="
                  infoData.photo ? `${apiUrl}/image?name=${infoData.photo}` : none
                "
                class="info-logo"
              />
            </div>
          </div>
          <div class="info-text">
            <p>{{ infoData.name }}</p>
            <p>{{ infoData.phone }}</p>
          </div>
        </div>
        <!-- <div>
          <van-icon name="arrow" />
        </div>-->
      </div>

      <div class="person-view-main-item">
        <span>卡内余额</span>
        <span>{{ Number(infoData.balance / 100).toFixed(2) }}</span>
      </div>
      <div class="person-view-main-item">
        <span>补助金</span>
        <span>{{ Number(infoData.allowance / 100).toFixed(2) }}</span>
      </div>
      <div class="person-view-main-item">
        <span>优惠余额</span>
        <span>{{ Number(infoData.coupon / 100).toFixed(2) }}</span>
      </div>
      <div class="person-view-main-item">
        <span>积分</span>
        <span>{{ infoData.points }}</span>
      </div>
      <div class="person-view-main-item">
        <span>性别</span>
        <span>{{ infoData.gender === 1 ? "男" : "女" }}</span>
      </div>
      <div class="person-view-main-item">
        <span>职业</span>
        <span>{{ infoData.role === 1? "学生" : "职工" }}</span>
      </div>
      <div v-if="infoData.role === 1" class="person-view-main-item">
        <span>是否住校</span>
        <span>{{ infoData.lodging === 1 ? "是" : "否" }}</span>
      </div>
      <!-- <div v-show="isClassManager || isManager" class="person-view-main-item" @click="toPage('FaceCheck')">
        <span>人脸审核</span>
        <span><van-icon style="line-height:3rem;margin-left:0.5rem;vertical-align: bottom;" name="arrow" /></span>
      </div>-->
      <!-- <div class="person-view-main-item">
        <span>班主任姓名</span>
        <span>{{ infoData.departmentManager.manager }}</span>
      </div>
      <div class="person-view-main-item">
        <span>班主任电话</span>
        <span>{{ infoData.gender }}</span>
      </div>-->
    </form>
  </div>
</template>

<script>
import none from "@/assets/images/photo_none.png";
import cancel from "@/assets/cancel.png";
// import { getAccInfo } from '@/api/person'

export default {
  data() {
    return {
      cancel,
      none,
      picUrl: "",
      apiUrl: process.env.VUE_APP_BASE_API,
      infoUrl: null,
      infoData: {
        id: null,
        name: null,
        phone: null,
        photo: null,
        balance: null,
        allowance: null,
        points: null,
        gender: null,
        role: null,
        lodging: null,
        isClassManager: false,
        isReview: null,
      },
      isClassManager: false,
      isManager: false,
      isChecked: false,
    };
  },
  created() {
    this.getInfoList();
    console.log("个人中心", window.location.href);
    // this.getInfo()
  },
  methods: {
    toPage(val) {
      this.$router.push(val);
    },
    // sleep(numberMillis) {
    //   var now = new Date()
    //   var exitTime = now.getTime() + numberMillis
    //   while (true) {
    //     now = new Date()
    //     if (now.getTime() > exitTime) return
    //   }
    // },
    toBack() {
      this.$router.back();
    },

    // async getInfo() {
    //   const res = await getAccInfo()
    //   console.log(res)
    //   if (res.code === 0) {
    //     this.infoData = res.data
    //     sessionStorage.setItem('personInfo', JSON.stringify(this.infoData))
    //   }
    // }

    // 获取本地存储中的个人信息
    getInfoList() {
      this.infoData = JSON.parse(sessionStorage.getItem("personInfo"));
      // console.log('个人信息', this.infoData)
      this.name = this.infoData.name;
      // this.infoData.isReview = 0
      this.isClassManager = this.infoData.isClassManager;
      this.isManager = this.infoData.isManager;
      // this.picUrl = JSON.parse(localStorage.getItem('picUrl'))
      this.picUrl = this.infoData.photo;
      // localStorage.removeItem('personInfo')
    },

    // async getInfoList() {
    //   const res = await getAccInfo()
    //   this.sleep(2000)
    //   console.log(res)
    //   if (res.code === 0) {
    //     this.$nextTick(() => {
    //       this.infoData = res.data
    //       this.picUrl = res.data.photo
    //       // this.infoUrl = `${this.apiUrl}/image?name=${this.picUrl}`
    //     })
    //   } else {
    //     console.log(111)
    //   }
    // this.infoData = res.data
    // this.picUrl = res.data.photo
    // this.infoUrl = `${this.apiUrl}/image?name=${this.picUrl}`
    // }
  },
};
</script>

<style lang="scss" scoped>
[v-cloak] {
  display: none !important;
}
.person-view {
  // background: #f1f1f1;
  height: 100%;
  display: flex;
  flex-direction: column;
  & > .nav {
    width: 100%;
    position: fixed;
    top: 0;
    display: block;
    text-align: center;
    height: 48px;
    color: white;
    overflow: hidden;
    border-bottom: 1px solid rgba(181, 201, 210, 0.6);
    background-color: #ffffff;

    & > .nav_title {
      display: block;
      font-size: 1.1rem;
      overflow: hidden;
      white-space: nowrap;
      line-height: 49px;
      text-overflow: ellipsis;
      color: #607483;
      width: 60%;
      margin: 0 auto;
      background-color: #ffffff;
    }

    & > .nav_left_btn {
      float: left;
      position: absolute;
      border-width: 9px 5px 9px 7px;
      border-color: transparent;
      background-size: 12px 1.25rem;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 4px 0 15px;
      top: 9px;
      left: 0;
      min-height: 30px;
      display: flex;
      align-items: center;
    }

    & > .nav_right_btn {
      float: right;
      position: absolute;
      border-width: 9px 4px 9px 3px;
      border-color: transparent;
      background: transparent;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 8px 0 2px;
      top: 8px;
      right: 0;
      min-width: 52px;
      min-height: 30px;
      display: flex;
      align-items: center;
    }
  }
  // 蒙层
  .cover {
    .info-cover {
      width: 120px;
      height: 120px;
      line-height: 80px;
      border-radius: 10px;
      margin-right: 20px;
      background: #ededed;
      border: #ededed solid 2px;
      position: absolute;
      top: 64px;
      left: 19px;
      text-align: center;
      z-index: 999;
      overflow: hidden;
      .info-logo {
        width: 80px;
        height: 80px;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 10px;
        margin-right: 20px;
      }
      .cover-text {
        width: 120px;
        height: 24px;
        border-radius: 12px;
        background: #517efd;
        color: #fff;
        text-align: center;
        position: absolute;
        font-size: 12px;
        top: 85px;
        left: -35px;
        z-index: 9999;
        transform: rotate(45deg);
        div {
          width: 120px;
          position: absolute;
          top: -27px;
          left: 0;
        }
      }
    }
  }
  .person-view-main {
    flex: 1;
    box-sizing: border-box;
    // padding: 20px;
    padding: 4rem 1.2rem 0 1.2rem;
    background: #fff;

    .person-view-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // margin-bottom: 20px;
      box-sizing: border-box;
      padding-bottom: 1rem;
      border-bottom: #ededed 1px solid;
      .person-view-info-left {
        display: flex;
        flex-direction: row;
        height: 120px;

        .info-logo {
          width: 120px;
          height: 120px;
          border-radius: 10px;
          margin-right: 20px;
        }
        .info-text {
          margin: 20px 0;
          display: flex;
          flex-direction: column;
          // justify-content: space-around;
          p {
            margin: 10px 0;
          }
        }
      }
    }
    .person-view-main-item {
      display: flex;
      justify-content: space-between;
      height: 3rem;
      line-height: 3rem;
      border-bottom: #ededed 1px solid;
    }
  }
}
</style>
